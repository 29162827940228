import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Featured projects`}</h2>
    <ProjectCard title="Hive" link="https://www.hivehome.com/hive-app" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Award winning React Native for British Gas IoT platform with over 1,500,000 active users in the UK
    </ProjectCard>
    <ProjectCard title="Brewdog EFP" link="https://www.brewdog.com/blog/efp-20m" bg="linear-gradient(to right,  #00a6d6 100%, #fffff0 0%)" mdxType="ProjectCard">
  Equity for Punks microfunding campaign with over £25,000,0000 raised on AWS Lambda for the coolest beer brand ever!
    </ProjectCard>
    <ProjectCard title="PEP App" link="https://www.nedapstaffingsolutions.com/nl/oplossingen/pep/pep-app/" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  The flexworkers React Native App in the Netherlands used by giants as Adecco
    </ProjectCard>
    <ProjectCard title="Ride-On" link="https://rideonglobal.com/en/index.html" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
React Native App for ROC, an electric-bike public sharing service consuming a Clojure backend.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      