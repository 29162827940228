import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { StaticImage } from "gatsby-plugin-image";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Acinonyx`}</h1>
    <p><em parentName="p">{`Your agile partner`}</em></p>
    <StaticImage src="./acinonyx_logo.png" alt="Logo" mdxType="StaticImage" />
    <p>{`We shape and implement your ideas into functional software to make a real difference in your business.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      